import React from 'react';
import { BrowserRouter, Route } from "react-router-dom";
import axios from 'axios';
import { v1 as uuidv1 } from 'uuid';
import Cookies from 'js-cookie';
import routesConfig from "./routes/routesConfig";
import ScrollToTop from './ui/ScrollToTop';
import CustomRouter from './ui/CustomRouter';
import { ToastContainer } from 'react-toastify';
import Header from './components/Header';
import Footer from './components/Footer';
import { store } from './store/store';
import { Provider } from 'react-redux';
import './styles/reset.scss';
import './global.scss';
import './styles/grid-layout.scss'
import './fonts/fonts.css';
import Meta from "./utils/Helmet/Meta";

const  App = () => {
  if (!Cookies.get('language')) {
      Cookies.set('language','ru')
  }
  axios.defaults.headers.common['X-localization'] = Cookies.get('language');

  if (!Cookies.get('device_token')) {
    Cookies.set('device_token', uuidv1());
  }
  axios.defaults.headers.common['Device-Token'] = Cookies.get('device_token');
  
  return (
      <>
        <Meta title="Главная" description="Главная страница Avtech" />
        <Provider store={store}>
        <div className="wrapper">
        <ToastContainer />
            <BrowserRouter>
              <Header />
              <main className="main">
                <ScrollToTop>
                  <CustomRouter>
                      {
                        routesConfig.map((route, index) => (
                          <Route key={index} path={route.path} element={<route.element />}></Route>
                        ))
                      }
                  </CustomRouter>
                </ScrollToTop>
              </main>
              <Footer />
          </BrowserRouter>
        </div>
      </Provider>
      </>
  );
}

export default App;
