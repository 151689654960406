import React, { useState, useEffect } from 'react'
import classNames from 'classnames';
import './BtnScrollUp.scss'

const BtnScrollUp = () => {
    const [scrolled, setScrolled] = useState(false)
    const handlerScrollUp = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });
    }
    const classes = classNames('btnScrollUp', {
      scrolled: scrolled,
    })
    useEffect(() => {
      const handleScroll = ( ) => { 
        if (window.pageYOffset > 300) {
          setScrolled(true)
        } else {
          setScrolled(false)
        }
      }
      window.addEventListener('scroll', handleScroll)
      return () => {
        window.removeEventListener('scroll', handleScroll)
      }
    }, [])

    
  return (
    <>
        {scrolled ?  
            <button onClick={handlerScrollUp} className={classes}>
                <svg width="12px" height="7px" viewBox="0 0 12 7" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                            <g transform="translate(-718.000000, -2466.000000)">
                            <g transform="translate(100.000000, 2404.000000)">
                                <g id="-Round-/-Hardware-/-keyboard_arrow_up" transform="translate(612.000000, 54.000000)">
                                <g>
                                    <rect  x={0} y={0} width={44} height={44} />
                                    <path d="M8.12,14.71 L12,10.83 L15.88,14.71 C16.27,15.1 16.9,15.1 17.29,14.71 C17.68,14.32 17.68,13.69 17.29,13.3 L12.7,8.71 C12.31,8.32 11.68,8.32 11.29,8.71 L6.7,13.3 C6.31,13.69 6.31,14.32 6.7,14.71 C7.09,15.09 7.73,15.1 8.12,14.71 Z" id="🔹-Icon-Color" fill="#1D1D1D" />
                                </g>
                                </g>
                            </g>
                            </g>
                        </g>
                </svg>
            </button>  
        : ''}
    </>
   
  )
}

export default BtnScrollUp