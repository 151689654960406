import React from 'react'
import lang from '../../langs/lang';
import classNames from 'classnames';
import { Link } from 'react-router-dom'
import st from './Assort.module.scss';
const Assort = () => {
    const assortItems = [
        {id: 0, slug: 'dlya-geymerov', name: [lang.for_gamers], imageUrl: 'img/assort-image/assort-image-1.png'},
        {id: 1, slug: 'tekhnika-dlya-doma', name: [lang.beloved_home], imageUrl: 'img/assort-image/assort-image-2.png'},
        {id: 2, slug: 'telefony-i-plansheti', name: [lang.mobile_workers], imageUrl: 'img/assort-image/assort-image-3.png'},
    ]
  return (
    <section className={classNames(st.assort, 'ind', 'section-color')}>
        <div className="container">
            <div className="head head--between mb-med">
                <h3 className="title title-primary p-right">{lang.сollections_title}</h3>
                <a href="/" className="btn-any">{lang.see_all}</a>
            </div>
            <div className={st.assort__items}>
                {assortItems.map((item) => (
                        <div key={item.id} className={st.assort__item}>
                                <div className={st['card-assort']}>
                                    <Link to={`/category-page/${item.slug}`} href="/" className={st['card-assort__body']}>
                                        <img className={st['card-assort__image']} src={item.imageUrl} alt="" />
                                        <span className={st['card-assort__text']}>{item.name}</span>
                                    </Link>
                                </div>
                        </div>
                ))}
            </div>
        </div>
    </section>
  )
}

export default Assort