import Cart from '../pages/Cart';
import Auth from '../pages/Auth';
import Registration from '../pages/Registration';
import Home from '../pages/Home';
import NotFound from '../pages/NotFound';
import SearchPage from '../pages/SearchPage/SearchPage';
import Categories from '../pages/Categories/Categories';
import ProductPage from '../pages/ProductPage';

const routesConfig = [
    {
        path: '*',
        element: NotFound,
    },
    {
        path: '/',
        element: Home,
    },
    {
        path: '/cart',
        element: Cart,
    },
    {
        path: '/auth',
        element: Auth,
    },
    {  path: '/registration',
        element: Registration,
    },
    {  path: '/categories/:slug',
        element: Categories,
    },
    {
        path: '/product-page/:slug',
        element: ProductPage
    },
    {
        path: '/search-page',
        element: SearchPage
    }
]
export default routesConfig;