import { useState, useEffect } from 'react';


function useDebounce (value, delay) {
    const [deboncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value)
        }, delay)
        return () => {
            clearTimeout(handler)
        }
    }, [value, delay])

    return deboncedValue;
}

export default useDebounce;