
import { configureStore } from '@reduxjs/toolkit';
import { productApi } from './products/product.api';
import cartSlice from "./cart/cartSlice";

export const store = configureStore({  
  reducer: {
    [productApi.reducerPath]: productApi.reducer,
    cart: cartSlice,
  },
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware().concat(productApi.middleware),
})
