import React from 'react'
// import loader from './img/search-notFound.svg'
const SearchNotFound = () => {
  return (
    <div className='search-notFound'>
        <div className="search-notFound__wrapper">
          <img className='search-notFound__image' src="img/search-notFound.png" alt="" />
        </div>
        <p className='search-notFound__text'>Извините, но мы ничего не нашли по такому запросу, попробуйте ввести что-нибудь снова</p>
    </div>
  )
}

export default SearchNotFound