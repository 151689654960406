import React from 'react';
import { Link } from 'react-router-dom'
import styles from './SearchItem.module.css';
const SearchItem = ( { searchItems = [], loader})  => {
  
  return (
    <>
        {searchItems.map((items) => (
                    <li onClick={loader} key={items.id} className={styles.itemSearch}>
                        <Link className={styles.itemSearch__link} to={`/product-page/${items.slug}`}>
                            <img src={items.image} alt="" />
                            <span className={styles.itemSearch__text}>{items.name}</span>
                        </Link>
                        <div className={styles.itemSearch__price}>{items.random_shop.price.toLocaleString()} сум</div>
                    </li>
                 )) 
            }
    </>
    
  )
}

export default SearchItem