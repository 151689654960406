import React, { useEffect, useState, useRef} from 'react'
import Card from '../../components/Card';
import { Navigation } from "swiper";
import { Swiper, SwiperSlide} from 'swiper/react';
import { CardLoad } from '../../ui/ContentLoad/ContentLoad';
import lang from '../../langs/lang';
import './RecProducts.scss';
import 'swiper/css';
import 'swiper/css/navigation';
import { useGetProductsQuery } from '../../store/products/product.api';
const RecProducts = ( ) => {
    const {data, error, isLoading} = useGetProductsQuery('/home?type=recommended_products');
    const prevRef = useRef();
    const nextRef = useRef();

  return (
        <section className="products ind section-color">
            <div className="container-second">
                <div className="page">
                <div className="page__row">
                    <div className="page__block">
                        <h3 className="page__title">{data?.data?.name}</h3>
                        <a href="/" className="page__link">{lang.see_all}
                            <svg width="21" height="12" viewBox="0 0 21 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20.5303 6.53033C20.8232 6.23744 20.8232 5.76256 20.5303 5.46967L15.7574 0.696699C15.4645 0.403806 14.9896 0.403806 14.6967 0.696699C14.4038 0.989593 14.4038 1.46447 14.6967 1.75736L18.9393 6L14.6967 10.2426C14.4038 10.5355 14.4038 11.0104 14.6967 11.3033C14.9896 11.5962 15.4645 11.5962 15.7574 11.3033L20.5303 6.53033ZM0 6.75H10V5.25H0V6.75ZM10 6.75H20V5.25H10V6.75Z" fill="white"/>
                            </svg>
                        </a>
                    </div>
                    <div className="page__content">
                        <div className="products__slider">
                            <>
                            {isLoading ? <>{[...new Array(3)].map(() => <CardLoad className="mr-small" /> )} </>
                                      : <Swiper
                                            modules={[Navigation]}
                                            autoplay={{
                                                delay: 5000,
                                                disableOnInteraction: false,
                                            }}
                                            spaceBetween={10}
                                            speed={1000}
                                            updateOnWindowResize
                                            observer
                                            observeParents
                                            breakpoints={ {
                                                1401: {
                                                    slidesPerView: 3.1,
                                                },
                                                650: {
                                                    slidesPerView: 2.5,
                                                },
                                                475: {
                                                    slidesPerView: 1.7,
                                                },
                                                1: {
                                                    slidesPerView: 1.3,
                                                },
                                            }  
                                            }
                                            navigation={{
                                                prevEl: prevRef?.current,
                                                nextEl: nextRef?.current
                                            }}
                            >
                                    {data?.data?.product_request?.map((recProduct, index) => (
                                        <SwiperSlide key={index}>
                                            <Card 
                                                id={recProduct.random_shop.item_shop_id}
                                                slug={recProduct.slug}
                                                key={index}
                                                cashBack={recProduct.random_shop.cashback}
                                                name={recProduct.name}
                                                imageUrl={recProduct.images[0].types.medium_default}
                                                price={recProduct.random_shop.price}
                                                monthly_price={recProduct.random_shop.monthly_price}
                                                categoryName={recProduct.class.name}
                                                onAddToCart={() => console.log('Добавлен в корзину')}
                                                />
                                        </SwiperSlide>
                                    ))}
                                
                                        </Swiper>
                            }
                             <div className="swiper-button-prev arrow-any" ref={prevRef}>
                                    <svg width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.555935 8.14018L8.41416 0.282102C8.59591 0.100207 8.83853 0 9.09724 0C9.35594 0 9.59856 0.100207 9.78031 0.282102L10.359 0.860662C10.7356 1.23766 10.7356 1.85039 10.359 2.22681L3.76027 8.82556L10.3663 15.4316C10.5481 15.6135 10.6484 15.856 10.6484 16.1146C10.6484 16.3734 10.5481 16.6159 10.3663 16.7979L9.78763 17.3763C9.60574 17.5582 9.36326 17.6584 9.10456 17.6584C8.84586 17.6584 8.60323 17.5582 8.42148 17.3763L0.555935 9.51107C0.373754 9.3286 0.273689 9.08497 0.274264 8.82599C0.273689 8.56599 0.373754 8.32251 0.555935 8.14018Z"></path>
                                    </svg>
                             </div>
                             <div className="swiper-button-next arrow-any" ref={nextRef}>
                                    <svg width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.4441 8.14018L2.58584 0.282102C2.40409 0.100207 2.16147 0 1.90276 0C1.64406 0 1.40144 0.100207 1.21969 0.282102L0.640986 0.860662C0.26442 1.23766 0.26442 1.85039 0.640986 2.22681L7.23973 8.82556L0.633664 15.4316C0.451913 15.6135 0.351562 15.856 0.351562 16.1146C0.351562 16.3734 0.451913 16.6159 0.633664 16.7979L1.21237 17.3763C1.39426 17.5582 1.63674 17.6584 1.89544 17.6584C2.15414 17.6584 2.39677 17.5582 2.57852 17.3763L10.4441 9.51107C10.6262 9.3286 10.7263 9.08497 10.7257 8.82599C10.7263 8.56599 10.6262 8.32251 10.4441 8.14018Z"></path>
                                    </svg>
                             </div>
                            </>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </section>
  )
}

export default RecProducts