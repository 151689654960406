import React, { useState, useEffect} from 'react'
import axios from 'axios';
import { useParams } from 'react-router-dom'

import { API_Products} from '../../constants/api';
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs';
import ProductPageSlider from './ProductPageSlider/ProductPageSlider';
import ProductPageInfo from './ProductPageInfo/ProductPageInfo';
import ProductPageFeatures from './ProductPageFeatures/ProductPageFeatures';
import './ProductPage.scss'
import Meta from "../../utils/Helmet/Meta";
import {Helmet} from "react-helmet";


const ProductPage = () => {
  const { slug } = useParams();
  const [loader, setLoader] = useState(true);
  const [productImage, setProductImage] = useState([])
  const [productInfo, setProductInfo] = useState({});
  const [productFeatures, setProductFeautes] = useState([]);
  console.log(productInfo)
  useEffect(() => {
    (async () => {
      setLoader(true)
      const { data } = await axios.get(API_Products, {
        params: {
          slug: slug
        }
      })
      setProductImage(data.data)
      setProductInfo(data.data);
      setProductFeautes(data.data.features)
      setLoader(false)
    })()
  }, [slug])

  return (
    <>
      <Meta title={productInfo.meta_title} description={productInfo.meta_description} keywords={productInfo.meta_keywords} />
        <BreadCrumbs breads={[productInfo.name]}/>
          <section className="products-inner ind">
            <div className="container">
                <div className="products-inner__row">
                  <ProductPageSlider productImage={productImage} />
                  <ProductPageInfo productInfo={productInfo} loader={loader} />
                </div>
            </div>
          </section>

        <ProductPageFeatures productFeatures={productFeatures} />
    </>
  )
}

export default ProductPage