import React from 'react'
import PopularCategoriesItem from './PopularCategoriesItem';
import './PopularCategories.scss';
import lang from '../../langs/lang';
const PopularCategories = () => {
    const popularCategories = [
      { id: 1, slug: 'telefony-i-plansheti', imageUrl: 'url(img/population/population-image.png)',  name: `${lang.devices}`},
      { id: 2, slug: 'orgtekhnika', imageUrl: 'url(img/population/population-image-3.png)', name: `${lang.office_equipment}`},
      { id: 3, slug: 'sotovye-telefony-i-aksessuary', imageUrl: 'url(img/population/population-image-4.png)', name: `${lang.accessories}`},
      { id: 4, slug: 'dlya-geymerov', imageUrl: 'url(img/population/population-image-5.png)', name: `${lang.gamers}`},
      { id: 5, slug: 'noutbuki-2', imageUrl: 'url(img/population/population-image-6.png)', name: `${lang.laptops}`},
      { id: 6, slug: 'kompyutery', imageUrl: 'url(img/population/population-image-7.png)', name: `${lang.computers}`},
      { id: 7, slug: 'komplektuyushchie', imageUrl: 'url(img/population/population-image-9.png)', name: `${lang.computer_parts}`},
      { id: 8, slug: 'periferiynye-ustroystva', imageUrl: 'url(img/population/population-image-10.png)', name: `${lang.periphery}`},
    ]
  return (
    <section className="population ind">
      <div className="container">
          <div className="head head--between mb-med">
              <h2 className="title title-primary p-right">{lang.categories_title}</h2>
              <a href="/" className="btn-any">{lang.see_all}</a>
          </div>
          <div className="population__items">
              <PopularCategoriesItem popularCategories={popularCategories} />
          </div>
      </div>
    </section>
  )
}

export default PopularCategories