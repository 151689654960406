import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination} from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';

const BannerSlide = ( { banners }) => {
  return (
    <Swiper
    modules={[Navigation, Pagination]}
    navigation
    spaceBetween={0}
    observer={true}
    autoplay={{
      delay: 5000,
      disableOnInteraction: false,
    }}
    slidesPerView={1}
    pagination={{ clickable: true }}
    loop={false}
  >
      {banners.map((banner, index) => (
          <SwiperSlide key={index}>
              <img src={banner.background.url} alt="" />
          </SwiperSlide>
      ))}
   
    </Swiper>
  )
}

export default BannerSlide;
