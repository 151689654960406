import React from 'react';
import { Link } from 'react-router-dom';
import lang from '../../langs/lang';
import classNames from 'classnames';
import {useDispatch} from "react-redux";
import { addToCart } from "../../store/cart/thunk";
import 'react-toastify/dist/ReactToastify.css';
import './Card.scss';

const Card = ( { id, name, categoryName, imageUrl, monthly_price, price, slug, className} ) => {
  const dispatch = useDispatch()
  return (
        <div className={classNames('card-product', `${className}`)}>
            <div className="card-product__body">
                <div className="card-product__badge badge">
                    <button className="badge-item favorite-product">
                        <svg width="27" height="23" viewBox="0 0 27 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.9983 23C13.6281 23 13.2713 22.8667 12.9932 22.6245C11.9428 21.7113 10.9302 20.8531 10.0368 20.0961L10.0322 20.0922C7.4128 17.8727 5.15085 15.9559 3.57703 14.0678C1.81773 11.957 0.998291 9.95565 0.998291 7.76929C0.998291 5.64507 1.73085 3.68534 3.06088 2.25085C4.40678 0.799395 6.25356 0 8.2616 0C9.76242 0 11.1369 0.471787 12.3467 1.40215C12.9573 1.87176 13.5107 2.44651 13.9983 3.11691C14.4861 2.44651 15.0393 1.87176 15.6501 1.40215C16.8599 0.471787 18.2344 0 19.7352 0C21.743 0 23.59 0.799395 24.9359 2.25085C26.2659 3.68534 26.9983 5.64507 26.9983 7.76929C26.9983 9.95565 26.179 11.957 24.4198 14.0676C22.8459 15.9559 20.5842 17.8725 17.9652 20.0918C17.0702 20.8499 16.0559 21.7095 15.0032 22.6249C14.7253 22.8667 14.3682 23 13.9983 23ZM8.2616 1.51437C6.68401 1.51437 5.23476 2.1404 4.18045 3.27726C3.11047 4.43128 2.52113 6.02652 2.52113 7.76929C2.52113 9.60812 3.20847 11.2527 4.74956 13.1016C6.23907 14.8887 8.45461 16.766 11.0199 18.9397L11.0246 18.9437C11.9214 19.7036 12.938 20.5651 13.9961 21.485C15.0605 20.5634 16.0787 19.7005 16.9773 18.9393C19.5424 16.7656 21.7577 14.8887 23.2472 13.1016C24.7881 11.2527 25.4754 9.60812 25.4754 7.76929C25.4754 6.02652 24.8861 4.43128 23.8161 3.27726C22.762 2.1404 21.3126 1.51437 19.7352 1.51437C18.5795 1.51437 17.5185 1.87965 16.5816 2.59996C15.7467 3.24215 15.1651 4.05397 14.8241 4.62201C14.6487 4.91412 14.3401 5.08847 13.9983 5.08847C13.6565 5.08847 13.3479 4.91412 13.1725 4.62201C12.8317 4.05397 12.2501 3.24215 11.415 2.59996C10.4781 1.87965 9.41707 1.51437 8.2616 1.51437Z" fill="#BEBEBE"/>
                        </svg>
                    </button>
                    <button className="badge-item compare-product">
                        <svg width="21" height="25" viewBox="0 0 21 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="20.9983" width="25" height="2" transform="rotate(90 20.9983 0)" fill="#BEBEBE"/>
                        <rect x="11.9983" y="13" width="12" height="2" transform="rotate(90 11.9983 13)" fill="#BEBEBE"/>
                        <rect x="2.99829" y="4" width="21" height="2" transform="rotate(90 2.99829 4)" fill="#BEBEBE"/>
                        </svg>  
                    </button>
                </div>
                <Link to={`/product-page/${slug}`} className="card-product__image">
                    <img src={imageUrl} alt="" />
                </Link>
                <div className="card-product__content">
                    <Link to={`/product-page/${slug}`} className="card-product__desc">
                        <p className="card-product__category">{categoryName}</p>
                        <h4 className="card-product__title" data-text-limit="56">{name}</h4>
                    </Link>
                    <div className="card-product__stock">
                        <div style={{display: 'none'}} className="countdown">
                            <p className="countdown__text">Осталось до конца:</p>
                            <div className="countdown__wrapper">
                            <div className="countdown__time">
                                <div className="countdown__count js-days">00</div>
                                <span>дней</span>
                            </div>
                            <div className="countdown__time">
                                <div className="countdown__count js-hours">00</div>
                                <span>часов</span>
                            </div>
                            <div className="countdown__time">
                                <div className="countdown__count js-minutes">00</div>
                                <span>минут</span>
                            </div>
                            <div className="countdown__time">
                                <div className="countdown__count js-seconds">00</div>
                                <span>секунд</span>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-product__footer">
                    <div className="card-product__price p-right">
                        <div className="price-mounth">{monthly_price.toLocaleString()} {lang.currency} / {lang.mounth}</div>
                        <div className="price-fix">{price.toLocaleString()} {lang.currency}</div>
                    </div>
                    <button onClick={() => dispatch(addToCart(id))} className="card-product__btn btn">{lang.add_to_cart}</button>
                </div>
            </div>      
        </div>
  )
}

export default Card