import axios from 'axios';
export const getApiProducts = async (url) => {
    
    return await axios.get(url)
        .then(res =>  {
           
            return res.data
        })
        .catch(error =>  {
            console.log(error)
        })
}   

export const getCategories = async (url, slug) => {
    return await axios.get(url, {
        params: {
            slug: slug
          }
         
    })
    .then(res =>  {
        return res.data
    })
    .catch(error =>  {
        console.error(error)
    })
}   

