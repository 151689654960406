import React from 'react'
import lang from '../../../langs/lang';
import { addToCart } from "../../../store/cart/thunk";
import { ProductPageLoad } from '../../../ui/ContentLoad/ContentLoad';
import {useDispatch} from "react-redux";
import './ProductPage.scss';
const ProductPageInfo = ({ productInfo = [], loader}) => {
   const dispatch = useDispatch()
  return (
    <div className="products-inner__content content-products">
    {loader ? 
        <ProductPageLoad />
        :
        <>
            <h1 className="content-products__title">{productInfo.name}</h1>
            <div className="content-products__desc">
            <p dangerouslySetInnerHTML={{ __html: productInfo.description }} />
            </div>
            <div className="content-products__footer">
            <div className="content-products__price">
                {productInfo.random_shop ? productInfo.random_shop.price.toLocaleString() : 0} {lang.currency}
            </div>
            <div className="content-products__buttons">
                <button onClick={() => dispatch(addToCart(productInfo.random_shop.item_shop_id))} className="content-products__buy btn">{lang.add_to_cart}</button>
                <button className="content-products__any icon icon--active">
                <svg width={24} height={21} viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.8584 20.7945C11.5208 20.7945 11.1953 20.674 10.9416 20.455C9.98347 19.6294 9.05974 18.8535 8.24477 18.1691L8.24061 18.1655C5.85122 16.1589 3.78791 14.4259 2.35229 12.7188C0.747485 10.8104 0 9.001 0 7.02429C0 5.10376 0.668231 3.33195 1.88147 2.03501C3.10918 0.72274 4.79378 0 6.62549 0C7.99452 0 9.24829 0.426547 10.3519 1.26769C10.9088 1.69228 11.4137 2.21191 11.8584 2.81803C12.3034 2.21191 12.808 1.69228 13.3652 1.26769C14.4687 0.426547 15.7225 0 17.0915 0C18.9231 0 20.6078 0.72274 21.8356 2.03501C23.0488 3.33195 23.7168 5.10376 23.7168 7.02429C23.7168 9.001 22.9695 10.8104 21.3647 12.7187C19.9291 14.4259 17.866 16.1587 15.477 18.1652C14.6605 18.8506 13.7354 19.6278 12.7751 20.4554C12.5216 20.674 12.1959 20.7945 11.8584 20.7945ZM6.62549 1.36916C5.18643 1.36916 3.86445 1.93515 2.90272 2.963C1.9267 4.00637 1.38912 5.44864 1.38912 7.02429C1.38912 8.68679 2.01609 10.1736 3.42185 11.8452C4.78057 13.461 6.80155 15.1583 9.14153 17.1236L9.14587 17.1271C9.96393 17.8142 10.8913 18.5931 11.8564 19.4248C12.8274 18.5915 13.7562 17.8114 14.5759 17.1232C16.9157 15.1579 18.9365 13.461 20.2952 11.8452C21.7008 10.1736 22.3277 8.68679 22.3277 7.02429C22.3277 5.44864 21.7901 4.00637 20.8141 2.963C19.8526 1.93515 18.5304 1.36916 17.0915 1.36916C16.0374 1.36916 15.0695 1.69941 14.2149 2.35064C13.4533 2.93126 12.9227 3.66524 12.6117 4.1788C12.4517 4.4429 12.1702 4.60054 11.8584 4.60054C11.5467 4.60054 11.2651 4.4429 11.1051 4.1788C10.7943 3.66524 10.2638 2.93126 9.50197 2.35064C8.64737 1.69941 7.67949 1.36916 6.62549 1.36916Z" />
                </svg>
                </button>
                <button className="content-products__any icon">
                <svg width={19} height={23} viewBox="0 0 19 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="18.2437" width="22.6027" height="1.82437" transform="rotate(90 18.2437 0)" />
                    <rect x="10.0337" y="11.7534" width="10.8493" height="1.82437" transform="rotate(90 10.0337 11.7534)" />
                    <rect x="1.82422" y="3.61646" width="18.9863" height="1.82437" transform="rotate(90 1.82422 3.61646)" />
                </svg>
                </button>
            </div>
            </div>
        </>
    }
  </div>
  )
}

export default ProductPageInfo