
import React, { useEffect, useState }  from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { showCart} from "../../store/cart/thunk";
import { Link } from 'react-router-dom';
import CartItem from './CartItem';
import CartInfo from "./CartInfo";
import Preloader from '../../ui/Preloader/Preloader';
import lang from '../../langs/lang';
import './Cart.scss';
import Meta from "../../utils/Helmet/Meta";

const Cart = () => {
    const dispatch = useDispatch()
    const cartItems = useSelector(state => state.cart.cartItems.items);
    const { total_with_discount, status, error } = useSelector(state => state.cart);
    console.log(status)
    useEffect(() => {
        dispatch(showCart());
    }, [dispatch])
  return (
    <>
      <Meta title="Корзина" />
      <section className="cart ind">
        <div className="container">
            <div className="cart__wrapper">
                {status === 'loading' ?  <div className="cart__loader"><Preloader size='50' color='#000000' /></div> : <>
                        { cartItems?.length > 0 ?
                                <div className="cart__row">
                                    <div className="cart__content">
                                        <div className="cart__content-wrapper">
                                            <CartItem cartItems={cartItems} />
                                        </div>
                                    </div>
                                    <div className="cart__info">
                                        <CartInfo total_with_discount={total_with_discount} />
                                    </div>
                                </div>
                                 :
                                <div className='notProducts'>
                                    <svg version="1.1" x="0px" y="0px" viewBox="0 0 1000 1000" enableBackground="new 0 0 1000 1000">
                                    <g><path d="M67.2,241.7l300-32.3l53.7,64.8l-287.7,44L67.2,241.7z"/><path d="M432.3,277.7l95.9-57.2l412.6,97.6l-105.6,61.6L432.3,277.7z"/><path d="M498.2,467.7l337-82.7L990,418.5l-355.4,81.2L498.2,467.7z"/><path d="M486.8,465.1l-121.4,28.2L10,336.7l121.4-14.1L486.8,465.1z"/><path d="M366.3,507.3l120.5-24.6l2.6,307.2L164.8,593.5L142,402.6L366.3,507.3z"/><path d="M629.4,512.1l-131.1-31.1v309.7l287.7-114.4l36.9-206.7L629.4,512.1z"/><path d="M432.3,283"/><path d="M425.8,279.9l2.6,0.1l-5.6,159l-2.6-0.1L425.8,279.9z"/></g>
                                    </svg>
                                    <p>{lang.not_products}</p>
                                    <Link style={{marginTop: '20px'}} className='btn' to={'/'}>{lang.buy_some}</Link>
                                </div>
                            }
                        </>
                    }
             </div>
        </div>
      </section>
    </>
      
  )
}

export default Cart
