import React, {useState, useEffect} from 'react';
import axios from 'axios';
import useDebounce from '../../../hooks/useDebounce';
import Preloader from '../../../ui/Preloader/Preloader'
import SearchItem from '../SearchItem'
import lang from '../../../langs/lang';
import { useNavigate } from 'react-router-dom'
import './SearchForm.scss'



const SearchForm = () => {
    const navigate = useNavigate();
    const [search, setSearch] = useState('');        
    const [searchItems, setSearchItems] = useState();  
    const [loading, setLoading] = useState(false);
    const [opened, setOpened] = useState(true);
    const debouncedSearch = useDebounce(search, 500);
    const onChangeSearchInput = (event) => {
        setSearch(event.target.value);
        setLoading(true);
        setOpened(true);
    }

    const searchSubmit = (e) => {
        e.preventDefault();
        if (search) {
            navigate(`/search-page?query=${search}`);
        }
      };
    const closeSearch = () => {
        setOpened(false)        
    }
    
    const openAgain = () => {
        if(searchItems)
            setOpened(true)
    }

    useEffect(() => {
        async function fetchData () {
            const { data } = await axios.get(`https://api.bs365.uz/api/search?search=${debouncedSearch}&shop=1`);
            setSearchItems(data.products)
            setLoading(false);
            setOpened(true)
        }
        if (debouncedSearch)  {
            fetchData()
        } else {
            setOpened(false);
        }
  }, [debouncedSearch])    
  return (
    <div className="search">
         <form onSubmit={searchSubmit} className="search__form">
            <div className="search__input">
                <input 
                    value={search}
                    onChange={onChangeSearchInput}
                    onFocus={openAgain}
                    className="search__control" 
                    placeholder={lang.placeholder_search}
                    type="text" />
                    <button  className="search__btn">
                        <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.2121 8.0926C16.2121 12.562 12.5889 16.1852 8.11945 16.1852C3.65003 16.1852 0.0268555 12.562 0.0268555 8.0926C0.0268555 3.62318 3.65003 0 8.11945 0C12.5889 0 16.2121 3.62318 16.2121 8.0926ZM2.18341 8.0926C2.18341 11.371 4.84107 14.0286 8.11945 14.0286C11.3978 14.0286 14.0555 11.371 14.0555 8.0926C14.0555 4.81421 11.3978 2.15655 8.11945 2.15655C4.84107 2.15655 2.18341 4.81421 2.18341 8.0926Z" fill="white" />
                            <path fillRule="evenodd" clipRule="evenodd" d="M17.6195 19.0001L12.6935 14.0742L14.6128 12.1549L19.5387 17.0808L17.6195 19.0001Z" fill="white" />
                        </svg>
                    </button>
                   
            </div>
            { opened ?
                <div className="search__items">
                    {loading 
                            ? <div className='search__prealoder'>
                                    <Preloader color='#000000' size='30' />
                            </div>
                            : <ul className="search__list scroll">
                                <SearchItem searchItems={searchItems} loader={closeSearch}/>
                            </ul>
                    }
                </div>
                :
                ''
            }
           
        </form>
    </div>
  )
}


export default SearchForm