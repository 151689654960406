import React from 'react';
import lang from "../../langs/lang";

const CartInfo = ( { total_with_discount } ) => {
    return (
        <div className="info-block">
            <form autoComplete="off" action="#" className="info-block__form">
                <div className="info-block__wrapper">
                    <div className="info-block__promo send-any">
                        <input className="send-any__input" placeholder={lang.promo_code} type="text" />
                        <button className="send-any__btn">
                            <svg width="13" height="22" viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.4509 12.1245C13.0239 11.5515 13.0239 10.6224 12.4509 10.0493L3.11245 0.71089C2.53939 0.137839 1.61029 0.137839 1.03724 0.71089C0.46419 1.28394 0.46419 2.21304 1.03724 2.78609L9.33806 11.0869L1.03724 19.3877C0.464189 19.9608 0.464189 20.8899 1.03724 21.4629C1.61029 22.036 2.53939 22.036 3.11244 21.4629L12.4509 12.1245ZM10.4355 12.5543L11.4133 12.5543L11.4133 9.61952L10.4355 9.61952L10.4355 12.5543Z" fill="white"/>
                            </svg>
                        </button>
                    </div>
                    <div className="info-block__items">
                        <div className="info-block__item">
                            <span className="info-block__text">{lang.to_payments}: </span>
                            <span className="info-block__value">{total_with_discount.toLocaleString()} {lang.currency}</span>
                        </div>
                    </div>
                </div>
                <a href="ordering.html" className="info-block__btn btn">{lang.сheckout}</a>
            </form>
        </div>
    )
}

export default CartInfo