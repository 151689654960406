import React from 'react'
import { Link } from 'react-router-dom';
import lang from '../../langs/lang';
import './BreadCrumbs.scss';
const BreadCrumbs = ( { breads = [] }) => {
  return (
    <div className="breadcrumb bg-cover" style={{backgroundImage: `url()`}}>
        <div className="container">
          <nav aria-label="breadcrumb" className="breadcrumb__nav">
          <ul className="breadcrumb__list">
                <li className="breadcrumb__item"><Link className="breadcrumb__link" to="/">{lang.home}</Link></li>
                {breads.map((item, index) => (
                    <>
                        <li key={index} className="breadcrumb__item">{item}</li>
                    </>
                ))}
            </ul>
            <div className="breadcrumb__title title title-any">{breads.at(-1)}</div>
          </nav>
        </div>
    </div>
  )
}

export default BreadCrumbs