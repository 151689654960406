const uz = {
    add_to_cart: 'Savatchaga',
    currency: 'so\'m',
    see_all: 'Barchasini ko\'rish',
    mounth: 'oy',
    promo_code: 'Promo kodni kiriting',
    to_payments: 'Jami to\'lov',
    сheckout: 'Tekshirib ko\'rmoq',
    for_gamers: 'Haqiqiy o\'yinchilar uchun',
    beloved_home: 'Sevimli uyingiz uchun',
    mobile_workers: 'Mobil ishchilar uchun',
    categories_title: 'Mashhur katerorialar',
    сollections_title: 'Tanlovlar siz uchun',
    bonuses_discounts: 'Chegirmalar va bonuslar', 
    delivery_payments: 'Yetkazib berish va to\'lash',
    buy_cashback: 'Cashback sotib olish qoidalari',
    exchange: 'Qaytish / almashtirish',
    adress: 'Toshkent, st. Abdulloh Qahhor, 49A',
    coupon_rules: 'Kupon qoidalari',
    service_center: 'Xizmat ko\'rsatish markazi',
    vacancy: 'Ishlar',
    department_sales: ' Savdo bo\'limi',
    department_installment: 'To\'lov bo\'limi',
    works_graph: 'Call-markaz ish vaqti:',
    every_day: 'Har kuni',
    to: 'gacha',
    about_company: 'Kompaniya haqida',
    contacts: 'Kontaktlar',
    politics: 'Maxfiylik siyosati',
    loyalty: 'Sodiqlik dasturi qoidalari',
    terms_use: 'Saytdan foydalanish shartlari',
    buyers: 'Xaridorlar',
    magazine: 'Internet magazinlar',
    home: 'Uy',
    categories: 'Kategoriyalar',
    product_added: "Mahsulot savatga qo'shildi",
    product_removed: 'Mahsulot olib tashlandi',
    placeholder_search: 'Tovar bo\'ylab izlash...',
    devices: 'Telefonlar va planshetlar',
    monoblocks: 'Monobloklar',
    office_equipment: 'Ofis uskunalari',
    accessories: 'Aksessuarlar',
    gamers: 'Geymerlar uchun',
    laptops: 'Noutbuklar',
    computers: 'Kompyuterlar',
    videocard: 'Videokartalar',
    monitors: 'Monitorlar',
    observation: 'CCTV nazorati',
    computer_parts: 'Kompyuter qismlari',
    periphery: 'Periferik qurilmalar',
    auth: 'Avtorizatsiya',
    favorites: 'Sevimlilar',
    comparison: 'Solishtirish',
    cart: 'Savatcha',
    all_brands: 'Barchasini ko\'rish',
    not_products: 'Savatingizda hech qanday mahsulot yo\'q',
    buy_some: 'Biror narsa sotib oling',
    features: 'Xususiyatlari',
    descBrand: 'sanoat, moliyaviy, savdo va turar-joy sektorlari uchun elektr va axborot infratuzilmasini tashkil etish bo\'yicha to\'liq yechimlarni taklif etadi. Mahsulotlari yuqori Yevropa sifat standartlariga javob beradigan ishonchli yetkazib beruvchi sifatida kompaniya texnik, kafolat va axborotni qo\'llab-quvvatlash xizmatlarining to\'liq spektrini taqdim etadi.'
    
} 
export default uz