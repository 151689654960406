import ContentLoader from "react-content-loader"

export const CardLoad = ( { className } ) => {
    return (
        <ContentLoader className={className}
            speed={2}
            width={420}
            height={589}
            viewBox="0 0 420 589"
            backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
            >
            <rect x="0" y="0" rx="0" ry="0" width="0" height="122" /> 
            <rect x="0" y="0" rx="0" ry="0" width="100%" height="280" /> 
            <rect x="149" y="37" rx="0" ry="0" width="0" height="53" /> 
            <rect x="0" y="296" rx="0" ry="0" width="200" height="20" /> 
            <rect x="0" y="334" rx="0" ry="0" width="70%" height="57" /> 
            <rect x="0" y="411" rx="0" ry="0" width="50%" height="23" /> 
            <rect x="0" y="447" rx="0" ry="0" width="50%" height="21" /> 
            <rect x="265" y="415" rx="0" ry="0" width="155" height="53" /> 
        </ContentLoader>  
    )
}

export const ProductPageLoad = () => {
    return (
        <ContentLoader
            speed={2}
            width='100%'
            height={530}
            viewBox="0 0 1000 530"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            >
            <rect x="0" y="0" rx="0" ry="0" width="50%" height="55" />
            <rect x="0" y="100" rx="0" ry="0" width="100%" height="25" />
            <rect x="0" y="135" rx="0" ry="0" width="100%" height="25" />
            <rect x="0" y="170" rx="0" ry="0" width="100%" height="25" />
            <rect x="0" y="205" rx="0" ry="0" width="100%" height="25" />
            <rect x="0" y="240" rx="0" ry="0" width="100%" height="25" />
            <rect x="0" y="275" rx="0" ry="0" width="100%" height="25" />
            <rect x="0" y="310" rx="0" ry="0" width="100%" height="25" />
            <rect x="0" y="345" rx="0" ry="0" width="100%" height="25" />
            <rect x="0" y="380" rx="0" ry="0" width="100%" height="25" />
            <rect x="0" y="470" rx="0" ry="0" width="300" height="45" />
      </ContentLoader>

    )
}


export const CategoriesLoad = ({className}) => {
    return (
        <ContentLoader style={className}
            speed={2}
            width={421}
            height={210}
            viewBox="0 0 421 210"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
        >
            <rect x="0" y="0" rx="0" ry="0" width="421" height="210" /> 
      </ContentLoader>
    )
}