import React from 'react'
import './CategoriesItem.scss';
const CategoriesItem = ( { items = [] } ) => {  
  return (
    <>
      {items.map((categories) => (
        <a href="/" key={categories.id} className="card-category card-category--big bg-cover" style={{backgroundImage: `url(${categories.preview})`}}>
                <div className="card-category__body">
                    <p>{categories.name}</p>
               </div>
        </a>
      ))}
    </>
  )
}

export default CategoriesItem