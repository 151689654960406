
const ru = {
    add_to_cart: 'В корзину',
    currency: 'сум',
    see_all: 'Смотреть всё',
    mounth: 'мес',
    promo_code: 'Введите промокод',
    to_payments: 'Всего к оплате',
    сheckout: 'Оформить заказ',
    for_gamers: 'Для настоящих геймеров',
    beloved_home: 'Для вашего любимого дома',
    mobile_workers: 'Для мобильных сотрудников',
    categories_title: 'Популярные категории',
    сollections_title: 'Подборки для вас',
    bonuses_discounts: 'Скидки и бонусы', 
    delivery_payments: 'Доставка и оплата',
    buy_cashback: 'Правила покупок с кэшбека',
    exchange: 'Возврат / обмен',
    coupon_rules: 'Правила пользования купонами',
    adress: 'Ташкент, ул. Абдуллы Каххара, 49А',
    service_center: 'Сервис центр',
    vacancy: 'Вакансии',
    department_sales: 'Отдел продаж',
    department_installment: 'Отдел рассрочки',
    works_graph: 'График работы колл-центра',
    every_day: 'Каждый день',
    to: 'До',
    about_company: 'О компании',
    contacts: 'Контакты',
    politics: 'Политика конфиденциальности',
    loyalty: 'Правила программы лояльности',
    terms_use: 'Правила пользования сайтом',
    buyers: 'Покупателям',
    magazine: 'Интернет магазин',
    home: 'Главная',
    categories: 'Категории',
    product_added: 'Товар добавлен в корзину',
    product_removed: 'Товар удален из корзины',
    placeholder_search: 'Поиск по товарам...',
    devices: 'Телефоны и планшеты',
    monoblocks: 'Моноблоки',
    office_equipment: 'Оргтехника',
    accessories: 'Акксесуары',
    gamers: 'Для геймеров',
    laptops: 'Ноутбуки',
    computers: 'Компьютеры',
    videocard: 'Видеокарты',
    monitors: 'Мониторы',
    observation: 'Видеонаблюдение',
    computer_parts: 'Компьютерные комплектующие',
    periphery: 'Переферийные устройства',
    auth: 'Авторизация',
    favorites: 'Избранное',
    comparison: 'Сравнение',
    cart: 'Корзина',
    all_brands: 'Все бренды',
    not_products: 'В вашей корзине нет товаров',
    buy_some: 'Купите что-нибудь',
    features: 'Характеристики', 
    descBrand: 'предлагает полный комплекс решений для организации электрической и информационной инфраструктур объектов промышленного, финансового, коммерческого и жилищного секторов. Являясь надежным поставщиком, чья продукция отвечает высоким стандартам европейского качества, компания оказывает полный спектр услуг технического, гарантийного и информационного сопровождения.'
} 
export default ru