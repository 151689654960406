import React from 'react'
import PopularCategory from '../../components/Popular/PopularCategories'
import Banners from '../../components/Banner'
import RecProducts from '../../components/RecProducts'
import Brands from '../../components/Brands'
import Assort from '../../components/Assort'
const Home = () => {

  return (

    <>
        <Banners />
        <RecProducts />
        <PopularCategory />
        <Assort />
        <Brands /> 
    </>
  )
}

export default Home