import React from 'react';
import { Link } from 'react-router-dom';
import Meta from "../../utils/Helmet/Meta";
import './Registration.scss';
const Registration = () => {
  return (
      <>
          <Meta title="Страница регистрации" />
          <section className="registration ind-any">
    <div className="container">
        <nav className="nav">
              <Link to="/auth" className='nav__link'>
                  Авторизация
              </Link>
              <Link to="/registration" className='nav__link active'>
                  Регистрация
              </Link>
        </nav>
        <div className="registration__content ind-block">
            <div className="registration-enter registration-enter--width">
                <div className="item-registration">
                    <a href="registration-fiz.html" className="item-registration__body">
                        <div className="item-registration__wrapper">
                            <svg width="98" height="118" viewBox="0 0 98 118" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M48.2361 56.6216C56.0059 56.6216 62.7339 53.8317 68.2312 48.3272C73.7284 42.8236 76.5151 36.0896 76.5151 28.3099C76.5151 20.5329 73.7284 13.798 68.2303 8.29263C62.7321 2.78992 56.005 0 48.2361 0C40.4655 0 33.7392 2.78992 28.242 8.29353C22.7448 13.7971 19.9572 20.532 19.9572 28.3099C19.9572 36.0896 22.7448 42.8245 28.2429 48.3281C33.741 53.8308 40.4682 56.6216 48.2361 56.6216Z" fill="#00348C"/>
                                <path d="M97.7169 90.3858C97.5584 88.0954 97.2377 85.597 96.7656 82.9586C96.2891 80.3005 95.6755 77.7877 94.941 75.491C94.1823 73.1172 93.1504 70.773 91.8748 68.5265C90.5509 66.1949 88.9959 64.1646 87.2509 62.4938C85.4263 60.746 83.1923 59.3407 80.6089 58.3157C78.0345 57.296 75.1815 56.7795 72.1297 56.7795C70.9312 56.7795 69.7721 57.2718 67.5336 58.7309C66.1559 59.6304 64.5445 60.6707 62.7458 61.8212C61.2078 62.8023 59.1242 63.7215 56.5507 64.5538C54.0399 65.3671 51.4906 65.7797 48.9745 65.7797C46.4583 65.7797 43.9099 65.3671 41.3964 64.5538C38.8256 63.7224 36.742 62.8032 35.2058 61.8221C33.4242 60.6823 31.8118 59.642 30.4135 58.73C28.1777 57.2709 27.0177 56.7786 25.8192 56.7786C22.7665 56.7786 19.9144 57.296 17.3409 58.3166C14.7593 59.3398 12.5244 60.7451 10.698 62.4947C8.95396 64.1664 7.39803 66.1958 6.0759 68.5265C4.80124 70.773 3.76933 73.1163 3.00973 75.4919C2.27611 77.7886 1.66252 80.3005 1.18598 82.9586C0.713916 85.5934 0.393236 88.0927 0.234688 90.3885C0.0788264 92.6377 0 94.972 0 97.3297C0 103.466 1.94827 108.433 5.79015 112.096C9.58457 115.711 14.6053 117.545 20.7107 117.545H77.2436C83.349 117.545 88.3679 115.712 92.1632 112.096C96.006 108.436 97.9543 103.467 97.9543 97.3288C97.9534 94.9604 97.8737 92.6242 97.7169 90.3858Z" fill="#00348C"/>
                            </svg>  
                        </div>
                        <span className="item-registration__text">Физическое лицо</span>
                    </a>
                </div>
                <div className="item-registration">
                    <a href="/" className="item-registration__body">
                        <div className="item-registration__wrapper">
                            <svg width="97" height="139" viewBox="0 0 97 139" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M28.9931 139V113.194H67.4588V139H96.4475V0H0V139H28.9931ZM67.4588 12.7692H83.6827V28.9931H67.4588V12.7692ZM67.4588 36.3544H83.6827V52.5826H67.4588V36.3544ZM67.4588 59.9439H83.6827V76.1633H67.4588V59.9439ZM67.4588 83.5246H83.6827V99.7572H67.4588V83.5246ZM40.1162 12.7692H56.3445V28.9931H40.1162V12.7692ZM40.1162 36.3544H56.3445V52.5826H40.1162V36.3544ZM40.1162 59.9439H56.3445V76.1633H40.1162V59.9439ZM40.1162 83.5246H56.3445V99.7572H40.1162V83.5246ZM12.7736 12.7692H28.9975V28.9931H12.7736V12.7692ZM12.7736 36.3544H28.9975V52.5826H12.7736V36.3544ZM12.7736 59.9439H28.9975V76.1633H12.7736V59.9439ZM12.7736 83.5246H28.9975V99.7572H12.7736V83.5246Z" fill="#00348C"/>
                            </svg>    
                        </div>
                        <span className="item-registration__text">Юридическое лицо</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>
      </>
  )
}

export default Registration