import { createSlice } from "@reduxjs/toolkit";
import {addToCart, removeCart, showCart} from "./thunk";


const initialState = {
    cartItems: [],
    total_with_discount: 0,
    totalItems: 0,
    status: null,
    error: null,
}
const setError = (state, action) => {
    state.status = 'rejected'
    state.error = action.payload
}
const cartSlice = createSlice({
    name: 'cart',
    initialState,
    extraReducers: {
        [showCart.pending]: (state) => {
            state.status = 'loading'
            state.error = null
        },
        [showCart.fulfilled]: (state, action ) => {
            state.cartItems = action.payload.data
            state.total_with_discount = action.payload.data.total_with_discount
            state.totalItems = action.payload.data.items.length
            state.status = 'resolved'
        },
        [showCart.rejected]: setError,
        [removeCart.rejected]: setError,
        [addToCart.rejected]: setError
    }
})
// export const {showCart, removeCart} = cartSlice.actions;
export default cartSlice.reducer