import React, {useState, useEffect} from 'react';
import { API_Brands } from '../../constants/api';
import { Swiper, SwiperSlide} from 'swiper/react';
import axios from 'axios';
import './Brands.scss';
import 'swiper/css';
import 'swiper/css/navigation';
import lang from '../../langs/lang';
const Brands = () => {
  const [name, setName] = useState('');
  const [brands, setBrands] = useState([]);
  const [currentBrand, setCurrentBrand] = useState({name: '', url: ''});  


  const getBrands = async () => {
    const { data } = await axios.get(API_Brands);
    setBrands(data.data.brands);
    setName(data.data.name);
    setCurrentBrand({
        name: data.data.brands[5].name,
        url: data.data.brands[5].image.url
    })
  }
  useEffect(() => {
     getBrands()
  }, [])
  return (
    <section className="brands ind">
        <div className="container">
            <div className="brands__head head mb-med">
                <h3 className="title title-primary p-right">{name}</h3>
                <div className="brands__controls">
                </div>
                <a href="/" className="btn-any">{lang.all_brands}</a>
            </div>
            <div className="brands__wrapper">
            <div className="brands__row">
                <div className="brands__banner">
                <div className="brands__banner-logo">
                    <img src={currentBrand.url} alt="" />
                </div>
                <div className="brands__banner-text">
                    <span style={{display: 'inline-block', marginRight: '10px'}}>{currentBrand.name}</span> 
                    {lang.descBrand}
                </div>
                </div>
                <div className="brands__category">
                <Swiper
                        direction="horizontal"
                        slidesPerView={3}
                        // slidesPerColumn='2'
                        slidesPerGroup={3}
                        spaceBetween={35}
                        grabCursor={true}
                        loop={false}
                    >
                        {brands.map((brand, index) => (
                            <SwiperSlide key={index}>
                                 <a href="/" className="card-brands">
                                    <span className="card-brands__text">{brand.name}</span>
                                 </a>
                            </SwiperSlide>
                        ))}
                    
                    </Swiper>
                </div>
            </div>
            </div>
        </div>
    </section>
  )
}

export default Brands