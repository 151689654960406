import { createAsyncThunk } from "@reduxjs/toolkit";
import {API_DeleteFromCart, API_ShowToCart, API_AddToCart} from "../../constants/api";
import Cookies from "js-cookie";
import axios from "axios";
import {ok} from "../../ui/Toastify/Toastify";
import lang from "../../langs/lang";

export const showCart = createAsyncThunk(
    'show/showCart',
    async (_, {rejectWithValue}) => {
        try {
            const response = await fetch(API_ShowToCart, {
                headers: {
                    "Device-Token":  Cookies.get('device_token'),
                    "Device-Type": 'web'
                }
            });
            if (!response.ok) {
                throw new Error('Server Error!');
            }
            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)
export const addToCart = createAsyncThunk(
    'addToCart/addToCart',
    async (item_shop_id, {dispatch}) => {
        try {
            const response = await axios.post(API_AddToCart, {
                device_token: Cookies.get('device_token'),
                device_type: "web",
                item_shop_id: item_shop_id,
                quantity: 1
            }).then(() => {
                dispatch(showCart())
                ok(lang.product_added)
            })
        } catch (error) {
            console.error(error)
        }

    }
)
export const removeCart = createAsyncThunk(
    'remove/removeCart',
    async function(item_shop_id, {dispatch}) {
        try {
            const response = await axios.delete(API_DeleteFromCart, {
                data: {
                    device_token: Cookies.get('device_token'),
                    device_type: "web",
                    item_shop_id: item_shop_id,
                }
            }).then(function (data) {
                dispatch(showCart())
                ok(lang.product_removed)
            })
        } catch (error) {
            console.error(error)
        }
    }
)