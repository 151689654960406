import React, { useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { API_CategoriesLinks } from '../../constants/api';
import axios from 'axios';
import Cookies from 'js-cookie';
import SearchForm from '../Search/SearchForm/SearchForm';
import lang from '../../langs/lang';
import './Header.scss';
import MobileMenu from '../Mobile/MobileMenu';
import {useSelector} from "react-redux";
const Header = () => {    
    const [categories, setCategories] = useState([]);
    const cartTotalItems = useSelector(state => state.cart.totalItems);

    const changeLanguage = (lang) => {
        Cookies.set('language', lang)
        window.location.reload();
    }

    const getCategoryLinks = async () => {
        const { data } = await axios.get(API_CategoriesLinks);   
        setCategories(data.data)
    }
    useEffect(() => {
        getCategoryLinks()
  }, [])   
  return (
    <>
        <header className="header js-header">
        <div className="any">
            <div className="container">
                <div className="any__row">
                    <div className="any__menu menu-any">
                        <a href="/" className="menu-any__link">{lang.delivery_payments}</a>
                        <a href="/" className="menu-any__link">{lang.service_center}</a>
                        <a href="/" className="menu-any__link">{lang.vacancy}</a>
                        <a href="/" className="menu-any__link">{lang.about_company}</a>
                        <a href="/" className="menu-any__link">{lang.contacts}</a>
                    </div>
                    <div className="any__language language">
                        <div className="language__item">
                            <div className="language__image">
                                <img src="/img/language-image/russian.png" width="23" height="16" alt="russian" />
                            </div>
                            <button onClick={() => changeLanguage('ru')} className="language__choice">Русский</button>
                        </div>
                        <div className="language__content">
                            <div className="language__item">
                                <div className="language__image">
                                <img src="/img/language-image/uz.png" width="23" height="16" alt="uzb" />
                                </div>
                                <button onClick={() => changeLanguage('uz')} className="language__choice">O'zbekcha</button>
                            </div>
                        </div>
                        <div className="language__arrows">
                            <svg width="14" height="7" viewBox="0 0 14 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.34102 6.5532L13.0782 0.816015C13.2616 0.626082 13.2564 0.323424 13.0664 0.139991C12.8812 -0.0389599 12.5874 -0.0389599 12.4022 0.139991L7.00301 5.53916L1.60384 0.13999C1.41716 -0.0466641 1.1145 -0.0466642 0.927813 0.13999C0.741159 0.326701 0.741159 0.629331 0.927813 0.816014L6.66499 6.5532C6.85171 6.73985 7.15434 6.73985 7.34102 6.5532Z"/>
                            </svg>    
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="container">
            <div className="header__top">
                <Link to='/' className='header__logo logo'>
                    <img src="/img/logo.jpg" alt="Avtech available technologies" />
                </Link>
                <div className="header__buttons">
                    <button className="menu-mobile js-btn-menu">
                        <span></span>
                        <span></span>
                        <span></span>
                    </button> 
                    <button className="category-btn js-category-btn">
                        <div className="category-btn__icon">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        <div className="category-btn__text">{lang.categories}</div>
                    </button>
                </div>
                <div className="header__search">
                    <SearchForm />
                </div>
                <div className="header__info info-link">
                    <Link to='/auth' className='info-link__item'>
                        <svg width="28" height="31" viewBox="0 0 28 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.882 17.104C13.9157 17.104 13.9494 17.104 13.9899 17.104C14.0034 17.104 14.0168 17.104 14.0303 17.104C14.0506 17.104 14.0775 17.104 14.0977 17.104C16.0729 17.073 17.6706 16.4355 18.8503 15.216C21.4456 12.5296 21.0142 7.92432 20.967 7.48483C20.7985 4.18561 19.0997 2.60718 17.6975 1.87058C16.6526 1.31968 15.4325 1.02256 14.0708 0.997803H14.0236C14.0168 0.997803 14.0034 0.997803 13.9966 0.997803H13.9562C13.2079 0.997803 11.7383 1.10922 10.3294 1.84582C8.91379 2.58242 7.18805 4.16085 7.01952 7.48483C6.97234 7.92432 6.5409 12.5296 9.13625 15.216C10.3092 16.4355 11.9069 17.073 13.882 17.104ZM8.81941 7.63958C8.81941 7.62101 8.82615 7.60244 8.82615 7.59006C9.04861 3.15189 12.4799 2.67527 13.9494 2.67527H13.9764C13.9899 2.67527 14.0101 2.67527 14.0303 2.67527C15.8504 2.71241 18.9446 3.3933 19.1536 7.59006C19.1536 7.60863 19.1536 7.6272 19.1604 7.63958C19.1671 7.68291 19.639 11.8921 17.4953 14.108C16.6459 14.987 15.5134 15.4203 14.0236 15.4327C14.0101 15.4327 14.0034 15.4327 13.9899 15.4327C13.9764 15.4327 13.9697 15.4327 13.9562 15.4327C12.4731 15.4203 11.3339 14.987 10.4912 14.108C8.35427 11.9044 8.81267 7.67672 8.81941 7.63958Z"/>
                                <path d="M27.8431 24.7424C27.8431 24.7363 27.8431 24.7301 27.8431 24.7239C27.8431 24.6744 27.8363 24.6248 27.8363 24.5691C27.7959 23.3435 27.7082 20.4776 24.7826 19.5615C24.7623 19.5553 24.7354 19.5491 24.7152 19.5429C21.6749 18.8311 19.147 17.2217 19.12 17.2031C18.7088 16.937 18.1425 17.0298 17.8527 17.4074C17.5628 17.785 17.6639 18.3049 18.0751 18.5711C18.1897 18.6454 20.8727 20.36 24.2298 21.1523C25.8005 21.6661 25.9758 23.2073 26.0229 24.6186C26.0229 24.6744 26.0229 24.7239 26.0297 24.7734C26.0364 25.3305 25.996 26.1909 25.8881 26.6861C24.7961 27.2556 20.5154 29.2239 14.0035 29.2239C7.51846 29.2239 3.21085 27.2494 2.11204 26.6799C2.00419 26.1847 1.957 25.3243 1.97048 24.7672C1.97048 24.7177 1.97722 24.6682 1.97722 24.6125C2.02441 23.2012 2.19968 21.6599 3.77037 21.1461C7.12747 20.3538 9.81045 18.633 9.92505 18.5649C10.3363 18.2987 10.4374 17.7788 10.1475 17.4012C9.85764 17.0236 9.29138 16.9308 8.88017 17.1969C8.85321 17.2155 6.33875 18.8249 3.28501 19.5367C3.25804 19.5429 3.23782 19.5491 3.2176 19.5553C0.291932 20.4776 0.204296 23.3435 0.163849 24.5629C0.163849 24.6186 0.163849 24.6682 0.157108 24.7177C0.157108 24.7239 0.157108 24.7301 0.157108 24.7363C0.150367 25.0581 0.143626 26.7108 0.500908 27.5403C0.568319 27.7012 0.68966 27.8374 0.851448 27.9303C1.05368 28.054 5.90058 30.889 14.0102 30.889C22.1198 30.889 26.9667 28.0479 27.1689 27.9303C27.324 27.8374 27.4521 27.7012 27.5195 27.5403C27.8565 26.717 27.8498 25.0643 27.8431 24.7424Z"/>
                            </svg>
                            <span>{lang.auth}</span>
                    </Link>
                    <Link to="/" className='info-link__item'>
                        <svg width="20" height="25" viewBox="0 0 20 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="20" width="25" height="2" rx="0.999999" transform="rotate(90 20 0)" fill="black"/>
                            <rect x="11" y="13" width="12" height="2" rx="1" transform="rotate(90 11 13)" fill="black"/>
                            <rect x="2" y="4" width="21" height="2" rx="1" transform="rotate(90 2 4)" fill="black"/>
                        </svg>
                        <span>{lang.comparison}</span>    
                    </Link>
                    <Link to="/" className='info-link__item'>
                        <div className="count">0</div>
                        <svg width="26" height="23" viewBox="0 0 26 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13 23C12.6299 23 12.273 22.8667 11.9949 22.6245C10.9445 21.7113 9.9319 20.8531 9.03847 20.0961L9.03391 20.0922C6.41451 17.8727 4.15256 15.9559 2.57874 14.0678C0.819443 11.957 0 9.95565 0 7.76929C0 5.64507 0.732559 3.68534 2.06259 2.25085C3.40849 0.799395 5.25526 0 7.26331 0C8.76413 0 10.1386 0.471787 11.3484 1.40215C11.959 1.87176 12.5124 2.44651 13 3.11691C13.4878 2.44651 14.041 1.87176 14.6518 1.40215C15.8616 0.471787 17.2361 0 18.7369 0C20.7447 0 22.5917 0.799395 23.9376 2.25085C25.2676 3.68534 26 5.64507 26 7.76929C26 9.95565 25.1808 11.957 23.4215 14.0676C21.8476 15.9559 19.5859 17.8725 16.9669 20.0918C16.0719 20.8499 15.0576 21.7095 14.0049 22.6249C13.727 22.8667 13.3699 23 13 23V23ZM7.26331 1.51437C5.68571 1.51437 4.23647 2.1404 3.18216 3.27726C2.11218 4.43128 1.52284 6.02652 1.52284 7.76929C1.52284 9.60812 2.21017 11.2527 3.75127 13.1016C5.24078 14.8887 7.45631 16.766 10.0216 18.9397L10.0263 18.9437C10.9231 19.7036 11.9397 20.5651 12.9978 21.485C14.0622 20.5634 15.0804 19.7005 15.979 18.9393C18.5441 16.7656 20.7594 14.8887 22.2489 13.1016C23.7898 11.2527 24.4772 9.60812 24.4772 7.76929C24.4772 6.02652 23.8878 4.43128 22.8178 3.27726C21.7637 2.1404 20.3143 1.51437 18.7369 1.51437C17.5812 1.51437 16.5202 1.87965 15.5833 2.59996C14.7484 3.24215 14.1668 4.05397 13.8258 4.62201C13.6504 4.91412 13.3418 5.08847 13 5.08847C12.6582 5.08847 12.3496 4.91412 12.1742 4.62201C11.8334 4.05397 11.2518 3.24215 10.4167 2.59996C9.47983 1.87965 8.41878 1.51437 7.26331 1.51437V1.51437Z"/>
                        </svg>
                        <span>{lang.favorites}</span>
                    </Link>
                    <Link to="/cart" className='info-link__item'>
                        <div className="count">{cartTotalItems}</div>
                        <svg width="22" height="25" viewBox="0 0 22 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19.6533 7.87362C19.597 7.27182 19.3159 6.72016 18.866 6.31896C18.4162 5.91775 17.8257 5.667 17.1228 5.667H15.6326V4.51354C15.6326 3.25978 15.0703 2.13139 14.1425 1.32899C13.2146 0.501505 11.9494 0 10.5717 0C9.1659 0 7.90067 0.501505 7.00095 1.32899C6.07311 2.13139 5.51079 3.25978 5.51079 4.51354V5.667H4.02063C3.31772 5.667 2.72728 5.91775 2.27742 6.31896C1.82756 6.72016 1.54639 7.27182 1.49016 7.87362L0 22.7182C0 22.7934 0 22.8686 0 22.8937C0 23.4453 0.253046 23.9719 0.702907 24.348C1.12465 24.6991 1.68698 24.9499 2.27742 25H2.30553C2.38988 25 2.47423 25 2.53046 25H18.613C18.6692 25 18.7535 25 18.8379 25C19.4565 24.9499 20.0188 24.6991 20.4405 24.348C20.8904 23.9719 21.1434 23.4453 21.1434 22.8686C21.1434 22.8185 21.1434 22.7683 21.1434 22.6931L19.6533 7.87362ZM7.05718 4.51354C7.05718 3.63591 7.45081 2.85858 8.09748 2.28185C8.74416 1.70512 9.61576 1.35406 10.5998 1.35406C11.5839 1.35406 12.4555 1.70512 13.1022 2.28185C13.7489 2.85858 14.1425 3.63591 14.1425 4.51354V5.667H7.05718V4.51354ZM19.5689 22.8937C19.5689 23.0943 19.4846 23.2698 19.3159 23.3952C19.1472 23.5456 18.8941 23.6459 18.6692 23.671C18.6692 23.671 18.6411 23.671 18.5849 23.671H2.53046C2.47423 23.671 2.44612 23.671 2.44612 23.671H2.418C2.16495 23.6459 1.94002 23.5456 1.77132 23.3952C1.60263 23.2698 1.51828 23.0943 1.51828 22.8937V22.8185L3.00844 7.999C3.03656 7.72317 3.17714 7.47242 3.37395 7.29689C3.54265 7.14644 3.76758 7.04614 4.02063 7.04614H5.51079V10.1053C5.51079 10.4814 5.84818 10.7823 6.26993 10.7823C6.69167 10.7823 7.02907 10.4814 7.02907 10.1053V7.04614H14.0581V10.1053C14.0581 10.4814 14.3955 10.7823 14.8173 10.7823C15.239 10.7823 15.5764 10.4814 15.5764 10.1053V7.04614H17.0666C17.3196 7.04614 17.5445 7.14644 17.7132 7.29689C17.9101 7.47242 18.0506 7.72317 18.0788 7.999L19.5689 22.8435V22.8937Z"/>
                        </svg>
                        <span>{lang.cart}</span>    
                    </Link>
                </div>
            </div>
        </div>
        <nav className="header__menu menu">
            <div className="container">
                <div className="menu__row">
                    {
                        categories.slice(0, 10).map((categories, index) => (
                            <Link to={`/categories/${categories.slug}`} key={index} className='menu__link'>
                                {categories.name}
                            </Link>
                        ))
                    }
                </div>
            </div>
            
        </nav>
        </header>
        <MobileMenu />
    </>
        //  <div className="mega-menu js-category-menu" style={{display: 'none'}}>
        //         <div className="mega-menu__wrapper">
        //             <div className="mega-menu__body js-parent">
        //                 <nav className="mega-menu__nav scroll">
        //                     <ul className="mega-menu__links">
        //                         <li><a className="tab-open" data-id="otopiteltexnika" href="/">Телефоны, планшеты</a></li>
        //                         <li><a className="tab-open" data-id="nasosi" href="/">Моноблоки</a></li>
        //                     </ul>
        //                 </nav>
        //                 <div className="mega-menu__content scroll content-menu">
        //                     <button className="back-nav js-menu-back">
        //                         <svg x="0px" y="0px"
        //                             width="44.952px" height="44.952px" viewBox="0 0 44.952 44.952">
        //                             <path d="M44.952,22.108c0-1.25-0.478-2.424-1.362-3.308L30.627,5.831c-0.977-0.977-2.561-0.977-3.536,0
        //                                 c-0.978,0.977-0.976,2.568,0,3.546l10.574,10.57H2.484C1.102,19.948,0,21.081,0,22.464c0,0.003,0,0.025,0,0.028
        //                                 c0,1.382,1.102,2.523,2.484,2.523h35.182L27.094,35.579c-0.978,0.978-0.978,2.564,0,3.541c0.977,0.979,2.561,0.978,3.538-0.001
        //                                 l12.958-12.97c0.885-0.882,1.362-2.059,1.362-3.309C44.952,22.717,44.952,22.231,44.952,22.108z"/>
        //                         </svg>
        //                     </button>
        //                     <div className="tab-content" data-id="otopiteltexnika">
        //                         <div className="mega-menu__item">
        //                             <div className="mega-menu__title">
        //                                 <span>Для геймеров</span>
        //                                 <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        //                                 <path d="M19.7071 8.70711C20.0976 8.31658 20.0976 7.68342 19.7071 7.29289L13.3431 0.928931C12.9526 0.538407 12.3195 0.538407 11.9289 0.928931C11.5384 1.31946 11.5384 1.95262 11.9289 2.34314L17.5858 8L11.9289 13.6569C11.5384 14.0474 11.5384 14.6805 11.9289 15.0711C12.3195 15.4616 12.9526 15.4616 13.3431 15.0711L19.7071 8.70711ZM8.74228e-08 9L19 9L19 7L-8.74228e-08 7L8.74228e-08 9Z" fill="#00348C"/>
        //                                 </svg>
        //                             </div>
        //                             <div className="mega-menu__list">
        //                                 <a href="/" className="mega-menu__link">Игровые кресла</a>
        //                                 <a href="/" className="mega-menu__link">Игровые клавиатуры</a>
        //                                 <a href="/" className="mega-menu__link">Игровые мыши</a>
        //                                 <a href="/" className="mega-menu__link">Беспроводные гарнитуры</a>
        //                                 <a href="/" className="mega-menu__link">Игровые кейсы</a>
        //                                 <a href="/" className="mega-menu__link">Игровые наушники</a>
        //                                 <a href="/" className="mega-menu__link">Игровые коврики</a>
        //                                 <a href="/" className="mega-menu__link">Игровые столы</a>
        //                                 <a href="/" className="mega-menu__link">Игровые аксессуары</a>
        //                             </div>
        //                         </div>
        //                     </div>
        //                     <div className="tab-content" data-id="nasosi">
        //                         <div className="mega-menu__item">
        //                             <div className="mega-menu__title">
        //                                 <span>Для моноблоков</span>
        //                                 <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        //                                 <path d="M19.7071 8.70711C20.0976 8.31658 20.0976 7.68342 19.7071 7.29289L13.3431 0.928931C12.9526 0.538407 12.3195 0.538407 11.9289 0.928931C11.5384 1.31946 11.5384 1.95262 11.9289 2.34314L17.5858 8L11.9289 13.6569C11.5384 14.0474 11.5384 14.6805 11.9289 15.0711C12.3195 15.4616 12.9526 15.4616 13.3431 15.0711L19.7071 8.70711ZM8.74228e-08 9L19 9L19 7L-8.74228e-08 7L8.74228e-08 9Z" fill="#00348C"/>
        //                                 </svg>
        //                             </div>
        //                             <div className="mega-menu__list">
        //                                 <a href="/" className="mega-menu__link">1</a>
        //                                 <a href="/" className="mega-menu__link">2</a>
        //                                 <a href="/" className="mega-menu__link">3</a>
        //                             </div>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //  </div> 
        //  <div className="mobile-menu">
        //     <div className="mobile-menu__body">
        //         <div className="mobile-menu__head">
        //             <div className="mobile-menu__language">
        //                 <div className="language">
        //                     <div className="language__item">
        //                         <div className="language__image">
        //                             <img src="img/language-image/russian.png" width="23" height="16" alt="russian" />
        //                         </div>
        //                         <div className="language__choice">Русский</div>
        //                     </div>
        //                     <div className="language__arrows">
        //                         <svg width="14" height="7" viewBox="0 0 14 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        //                             <path d="M7.34102 6.5532L13.0782 0.816015C13.2616 0.626082 13.2564 0.323424 13.0664 0.139991C12.8812 -0.0389599 12.5874 -0.0389599 12.4022 0.139991L7.00301 5.53916L1.60384 0.13999C1.41716 -0.0466641 1.1145 -0.0466642 0.927813 0.13999C0.741159 0.326701 0.741159 0.629331 0.927813 0.816014L6.66499 6.5532C6.85171 6.73985 7.15434 6.73985 7.34102 6.5532Z"/>
        //                         </svg>    
        //                     </div>
        //                 </div>
        //             </div>
        //             <button className="mobile-menu__close js-mobile-menu-close">
        //                 <svg height="24px" version="1.1" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg"><title/><desc/><defs/><g fill="none" fillRule="evenodd" id="miu" stroke="none" strokeWidth="1"><g id="Artboard-1" transform="translate(-575.000000, -155.000000)"><g id="slice" transform="translate(215.000000, 119.000000)"/><path d="M587.5,178 C593.851275,178 599,172.851275 599,166.5 C599,160.148725 593.851275,155 587.5,155 C581.148725,155 576,160.148725 576,166.5 C576,172.851275 581.148725,178 587.5,178 Z M587.5,177 C593.29899,177 598,172.29899 598,166.5 C598,160.70101 593.29899,156 587.5,156 C581.70101,156 577,160.70101 577,166.5 C577,172.29899 581.70101,177 587.5,177 Z M583.257359,171.449747 L582.550253,170.742641 L586.792893,166.5 L582.550253,162.257359 L583.257359,161.550253 L587.5,165.792893 L591.742641,161.550253 L592.449747,162.257359 L588.207107,166.5 L592.449747,170.742641 L591.742641,171.449747 L587.5,167.207107 L583.257359,171.449747 Z" fill="#000000" id="circle-close-delete-remove-outline-stroke"/></g></g></svg>
        //             </button>
        //         </div>
        //         <div className="mobile-menu__items">
        //             <div className="mobile-menu__item accordion">
        //                 <div className="mobile-menu__current accordion__button">
        //                     <span>Сервесное обслуживание</span>
        //                     <div className="mobile-menu__icon">
        //                         <svg x="0px" y="0px" viewBox="0 0 330 330">
        //                             <path id="XMLID_225_" d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393
        //                             c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393
        //                             s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z" />
        //                         </svg>
        //                     </div>
        //                 </div>
        //                 <div className="mobile-menu__content accordion__content hide-content">
        //                     <a href="/" className="mobile-menu__link">Серверы</a>
        //                     <a href="/" className="mobile-menu__link mobile-menu__link--more">Показать всё</a>
        //                 </div>
        //             </div>
        //             <div className="mobile-menu__item accordion">
        //                 <div className="mobile-menu__current accordion__button">
        //                     <span>Моноблоки</span>
        //                     <div className="mobile-menu__icon">
        //                         <svg x="0px" y="0px" viewBox="0 0 330 330">
        //                             <path id="XMLID_225_" d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393
        //                             c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393
        //                             s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z" />
        //                         </svg>
        //                     </div>
        //                 </div>
        //                 <div className="mobile-menu__content accordion__content hide-content">
        //                     <a href="/" className="mobile-menu__link">Для дома и офиса</a>
        //                     <a href="/" className="mobile-menu__link mobile-menu__link--more">Показать всё</a>
        //                 </div>
        //             </div>
        //             <div className="mobile-menu__item accordion">
        //                 <div className="mobile-menu__current accordion__button">
        //                     <span>Телефоны, планшеты</span>
        //                     <div className="mobile-menu__icon">
        //                         <svg x="0px" y="0px" viewBox="0 0 330 330">
        //                             <path id="XMLID_225_" d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393
        //                             c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393
        //                             s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z" />
        //                         </svg>
        //                     </div>
        //                 </div>
        //                 <div className="mobile-menu__content accordion__content hide-content">
        //                     <a href="/" className="mobile-menu__link">Для дома и офиса</a>
        //                     <a href="/" className="mobile-menu__link mobile-menu__link--more">Показать всё</a>
        //                 </div>
        //             </div>
        //             <div className="mobile-menu__item accordion">
        //                 <div className="mobile-menu__current accordion__button">
        //                     <span>Для геймеров</span>
        //                     <div className="mobile-menu__icon">
        //                         <svg x="0px" y="0px" viewBox="0 0 330 330">
        //                             <path id="XMLID_225_" d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393
        //                             c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393
        //                             s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z" />
        //                         </svg>
        //                     </div>
        //                 </div>
        //                 <div className="mobile-menu__content accordion__content hide-content">
        //                     <a href="/" className="mobile-menu__link">Для дома и офиса</a>
        //                     <a href="/" className="mobile-menu__link mobile-menu__link--more">Показать всё</a>
        //                 </div>
        //             </div>
        //             <div className="mobile-menu__item accordion">
        //                 <div className="mobile-menu__current accordion__button">
        //                     <span>Видеонаблюдение</span>
        //                     <div className="mobile-menu__icon">
        //                         <svg x="0px" y="0px" viewBox="0 0 330 330">
        //                             <path id="XMLID_225_" d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393
        //                             c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393
        //                             s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z" />
        //                         </svg>
        //                     </div>
        //                 </div>
        //                 <div className="mobile-menu__content accordion__content hide-content">
        //                     <a href="/" className="mobile-menu__link">Для дома и офиса</a>
        //                     <a href="/" className="mobile-menu__link mobile-menu__link--more">Показать всё</a>
        //                 </div>
        //             </div>
        //             <div className="mobile-menu__item accordion">
        //                 <div className="mobile-menu__current accordion__button">
        //                     <span>Аксессуары</span>
        //                     <div className="mobile-menu__icon">
        //                         <svg x="0px" y="0px" viewBox="0 0 330 330">
        //                             <path id="XMLID_225_" d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393
        //                             c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393
        //                             s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z" />
        //                         </svg>
        //                     </div>
        //                 </div>
        //                 <div className="mobile-menu__content accordion__content hide-content">
        //                     <a href="/" className="mobile-menu__link">Для дома и офиса</a>
        //                     <a href="/" className="mobile-menu__link mobile-menu__link--more">Показать всё</a>
        //                 </div>
        //             </div>
        //             <div className="mobile-menu__item accordion">
        //                 <div className="mobile-menu__current accordion__button">
        //                     <span>Товары для дома</span>
        //                     <div className="mobile-menu__icon">
        //                         <svg x="0px" y="0px" viewBox="0 0 330 330">
        //                             <path id="XMLID_225_" d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393
        //                             c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393
        //                             s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z" />
        //                         </svg>
        //                     </div>
        //                 </div>
        //                 <div className="mobile-menu__content accordion__content hide-content">
        //                     <a href="/" className="mobile-menu__link">Для дома и офиса</a>
        //                     <a href="/" className="mobile-menu__link mobile-menu__link--more">Показать всё</a>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        //  </div>
        //  <div className="popup js-popup-location">
        //     <div className="popup__wrapper">
        //         <div className="popup__inner">
        //         <div className="popup__content">
        //             <button className="popup__btn-close"><span className="i-close"></span></button>
        //         </div>
        //         </div>
        //     </div>
        //  </div>
   
  )
}

export default Header