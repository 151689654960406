import React, { useState } from 'react'
import { Swiper, SwiperSlide} from 'swiper/react';
import { Thumbs } from 'swiper';
import StickyBox from "react-sticky-box";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import 'swiper/css';
import 'swiper/css/navigation';
import './ProductPageSlider.scss';
const ProductPageSlider = ({ productImage = []}) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  return (
    <StickyBox className='product-slider' offsetTop={20} offsetBottom={20}>
      <Swiper 
        className='product-slider__gallery' 
        observer={true}
        observeParents={true}
        observeSlideChildren={true}
        modules={[Thumbs]}
        slidesPerView={3}
        onSwiper={setThumbsSwiper}
        spaceBetween= {10}
        breakpoints={
            {
              767: {
                  direction: "vertical",
                  spaceBetween: 30,
                  slidesPerView: 4,
              },
              0: {
                  direction: "horizontal",
                  
              },
          }
        } 
      >
        {
          productImage?.images?.map((image, index) => (
                <SwiperSlide key={index}>
                    <img src={image.url} alt="/" />
                </SwiperSlide>
          ))
        }
      </Swiper>
      <Swiper 
          className='product-slider__main' 
          observer={true}
          observeParents={true}
          observeSlideChildren={true}
          modules={[Thumbs]} 
          thumbs={{ swiper: thumbsSwiper }}>
              <svg className='product-slider__zoom' width="31.874px" height="31.874px" viewBox="-0.1 0 31.874 31.874" xmlns="http://www.w3.org/2000/svg">
                    <g data-name="Group 23" transform="translate(-1199.025 -182)">
                      <circle id="Ellipse_8" data-name="Ellipse 8" cx="11" cy="11" r="11" transform="translate(1200.025 183)" fill="#e8f7f9" stroke="#333" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                      <path id="Path_25" data-name="Path 25" d="M1211,187a7,7,0,0,0-7,7" fill="none" stroke="#333" strokeMiterlimit="10" strokeWidth="2"/>
                      <line id="Line_24" data-name="Line 24" x1="11.568" y1="11.568" transform="translate(1218.432 201.599)" fill="none" stroke="#333" strokeMiterlimit="10" strokeWidth="2"/>
                    </g>
            </svg>
          {
            productImage?.images?.map((image, index) => (
              <SwiperSlide key={index}>
                <div className="product-slider__wrap">
                  <Zoom  zoomMargin={100} overlayBgColorEnd="rgba(0, 0, 0, .85)">
                        <img src={image.url} alt="/" />
                  </Zoom>
                </div>
              </SwiperSlide>
            ))
          }
      </Swiper>
    </StickyBox>
  )
}

export default ProductPageSlider