import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'
import { getCategories } from '../../utils/network';
import { API_CategoriesLinks } from '../../constants/api';
import { CategoriesLoad } from '../../ui/ContentLoad/ContentLoad'
import CategoriesItem from '../../components/CategoriesItem/CategoriesItem';
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs';
import Meta from "../../utils/Helmet/Meta";

const Categories = () => {
  const { slug } = useParams();
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState([]);
  const [isloader, setIsLoader] = useState(true);

  useEffect(() => {
      (async () => {
        setIsLoader(true)
        const response = await getCategories(API_CategoriesLinks, slug);        
        setCategory(response.data);
        setCategories(response.data.childs);
        setIsLoader(false)
      })()
  }, [slug])

  return (
    <>
      <Meta title="Категории" />
      <BreadCrumbs breads={[category.name]}/>
      <section className="category-list ind">
        <div className="container">
            <div className="grid-four">
                {isloader ?  [...new Array(6)].map(() => <CategoriesLoad />) : <CategoriesItem items={categories} />}
            </div>
        </div>
      </section>
    </>
  )
}

export default Categories