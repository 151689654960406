import { createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import { BASE_URL } from '../../constants/baseUrl';
export const productApi = createApi({
    reducerPath: 'api/products',
    baseQuery: fetchBaseQuery({baseUrl: BASE_URL}),
    endpoints: build => ({
        getProducts: build.query({
            query: (url) => ({
                url: `${url}`,
            }),
        }),
        
    })
  
})  

export const { useGetProductsQuery} = productApi


