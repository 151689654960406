import React from 'react'
import lang from '../../../langs/lang'
import './ProductPageFeatures.scss';
const ProductPageFeatures = ( {productFeatures = []} ) => {
  return (
    <div className="feature mb-big">
        <div className="container">
        <div className="feature__content accordion active">
            <button className="feature__open mb-med accordion__button">{lang.features}
            <svg width={16} height={9} viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.29289 8.70711C7.68342 9.09763 8.31658 9.09763 8.70711 8.70711L15.0711 2.34315C15.4616 1.95262 15.4616 1.31946 15.0711 0.928932C14.6805 0.538408 14.0474 0.538408 13.6569 0.928932L8 6.58579L2.34315 0.928932C1.95262 0.538408 1.31946 0.538408 0.928932 0.928932C0.538408 1.31946 0.538408 1.95262 0.928932 2.34315L7.29289 8.70711ZM9 8L9 7L7 7L7 8L9 8Z" fill="#00348C" />
            </svg>    
            </button>
            <div className="feature__info info-feature">
            <div className="info-feature__block">
                {productFeatures.map((feature) => (
                            <div key={feature.id} className="info-feature__item">
                            <div className="info-feature__text">{feature.name}</div>
                            <div className="info-feature__value">{feature.value}</div>
                        </div>
                ))}
            </div>
            </div>
        </div>
        </div>
  </div>
  )
}

export default ProductPageFeatures