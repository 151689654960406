import React from 'react';
import { Link } from 'react-router-dom';
import './Auth.scss';
import Meta from "../../utils/Helmet/Meta";
const Auth = () => {
  return (
      <>
          <Meta title='Страница авторизации' />
          <section className="authentication ind-any">
        <div className="container">
            <nav className="nav">
              <Link to="/auth" className='nav__link active'>
                  Авторизация
              </Link>
              <Link to="/registration" className='nav__link'>
                  Регистрация
              </Link>
            </nav>
            <div className="authentication__content authentication__content--width ind-block">
                <form action="#">
                    <div className="form__row">
                      <div className="form__item form__item--grow">
                        <label className="form__label">Номер телефона</label>
                        <div className="form__input">
                            <input placeholder="+998 33 303 32 32" type="tel" className="form__control form-control" />
                        </div>
                      </div>
                      <div className="form__item form__item--grow">
                        <label className="form__label">Пароль</label>
                        <div className="form__input">
                            <input placeholder="Пароль" type="password" className="form__control form-control" />
                        </div>
                      </div>
                    </div>
                    <div className="authentication__buttons">
                        <button className="form__btn btn">Войти</button>
                        <a href="/" className="authentication__link">Забыли пароль</a>
                    </div>
                </form>
                <div className="authentication__any">У вас нет аккаунта? <Link to="/registration">Зарегистрироваться</Link></div>
            </div>
        </div>
    </section>
      </>
  )
}

export default Auth