import React, {useState, useEffect} from 'react'
import BannerSlide from './BannerSlide';
import { API_Banners } from '../../constants/api';
import axios from 'axios';
import 'swiper/css';
import './Banners.scss'
const Banners = () => {
  const [banners, setBanners] = useState([]);

  const getBanners = async () => {
    const { data } =  await axios.get(API_Banners);
    setBanners(data.data)    
  }
  
  useEffect(() => {
    getBanners();
  }, [])
  return (
    <section className="hero">
      <div className="hero__slider">      
          <BannerSlide banners={banners} />
      </div>
    </section>
  )
}

export default Banners