import Cookies from 'js-cookie';

import ru from './ru'
import uz from './uz'

const lang = Cookies.get('language')
var langs = {
    'ru':ru,
    'uz':uz
}

export default langs[lang];