import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import './NotFound.scss';
import Meta from "../../utils/Helmet/Meta";
const NotFound = () => {
  const location = useLocation();

  return (
      <>
          <Meta title="Страница не найдена" />
          <section className="notFound mb-big">
              <div className="container">
                  <div className="notFound__content">
                      <div className="notFound__row">
                          <div className="notFound__text">404</div>
                          <div className="notFound__any">
                              <p>К сожалению, указанная страница не найдена.</p>
                              <div style={{marginBottom: '20px'}}>{location.pathname}</div>
                              <Link className='notFound__btn' to="/">Вернуться на главную</Link>
                          </div>
                      </div>
                  </div>

              </div>
          </section>
      </>

  )
}

export default NotFound;