import React from 'react';
import { Link } from 'react-router-dom';
const PopularCategoriesItem = ( { popularCategories } ) => {  

  return (
    <>
        {
                  popularCategories.map((category) => (
                      <div key={category.id} className="population__item">
                          <Link to={`/categories/${category.slug}`} className="card-category bg-cover" style={{backgroundImage: `${category.imageUrl}`}}>
                              <div className="card-category__body">
                                  <p>{category.name}</p>
                              </div>
                          </Link>
                    </div>
                  ))
        }
    </>
  )
}

export default PopularCategoriesItem