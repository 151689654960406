import React from "react";
import { Helmet } from "react-helmet";
const Meta = ({title, description, keywords}) => {
    return <Helmet>
        <title>{title}</title>
        <meta name="description" description={description} />
        <meta name="keywords" content={keywords}/>
    </Helmet>
}

export default Meta;