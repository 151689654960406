
import React from 'react';
import { removeCart} from "../../store/cart/thunk";
import { useDispatch} from "react-redux";
import { Link } from 'react-router-dom';
import lang from '../../langs/lang';
import './Cart.scss';
const CartItem = ({ cartItems = []}) => {
  const dispatch = useDispatch();
  return (
    <>
        {cartItems.map((cartItem) => (
            <div key={cartItem.item_shop_id} className="item-cart">
                <Link to={`/product-page/${cartItem.product.slug}`} className="item-cart__start">
                    <div className="item-cart__image">
                        <img src={cartItem.product.images[0].url} alt="" />
                    </div>
                    <div className="item-cart__desc">{cartItem.product.name}</div>
                </Link>
                <div className="item-cart__content">
                    <div className="item-cart__any">
                        <div className="item-cart__count count-main">
                            <button className="count-main__button">-</button>
                            <input 
                                className="count-main__value" 
                                type="number" disabled 
                                value={cartItem.quantity}></input>
                            <button className="count-main__button">+</button>
                        </div>
                        <div className="item-cart__price">{cartItem.price.toLocaleString()} {lang.currency}</div>
                    </div>
                    <button onClick={() => dispatch(removeCart(cartItem.item_shop_id))} className="item-cart__delete">
                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.44138 0.130381L21.8609 20.5499L20.4196 21.9913L0 1.57176L1.44138 0.130381Z" fill="#00348C"/>
                            <path d="M20.4196 0.130371L5.24472e-06 20.5499L1.44139 21.9913L21.8609 1.57175L20.4196 0.130371Z" fill="#00348C"/>
                        </svg>
                    </button>
                </div>
            </div>
        ))}
    </>
  )
}

export default CartItem