import React, {useEffect, useState} from 'react'
import axios from 'axios';
// import { useSearchParams } from 'react-router-dom';
import Pagination from 'rc-pagination';
import BtnScrollUp from '../../ui/BtnScrollUp/BtnScrollUp';
import Card from '../../components/Card';
import SearchNotFound from './SearchNotFound';
import Preloder from '../../ui/Preloader/Preloader';
import { useParams, useSearchParams} from 'react-router-dom';
import ScrollToTop from '../../ui/ScrollToTop';
import './SearchPage.scss'
import Meta from "../../utils/Helmet/Meta";


const SearchPage = () => {
const [searchParams] = useSearchParams();
const [searchItems, setSearchItems] = useState([]);
const [isLoader, setIsLoader] = useState(true)
const [total, setTotal] = useState(0);
const [perPage, setPerPage] = useState(0);
const [current, setCurrent] = useState(1);

const onChange = page => {
  setCurrent(page);
  setIsLoader(true)
  getProducts()
  document.documentElement.scrollTo(0, 0);
};
const getNoun = (number) => {
  let n = Math.abs(number);
  n %= 100;
  if (n >= 5 && n <= 150) {
    return number + ' товаров';
  }
  n %= 10;
  if (n === 1) {
    return number + ' товар';
  }
  if (n >= 2 && n <= 4) {
    return number + ' товара';
  }
  return number + ' товаров';
}

const query = searchParams.get('query');

const getProducts = async () => {
  if (query) {
      const response = await axios.get(`https://api.bs365.uz/api/search`, {
        params: {
            search: query,
            page: current,
            shop: 1
        }
    })    
    
      setSearchItems(response.data.products)
      setTotal(response.data.meta.total)
      setPerPage(response.data.meta.per_page)
      setIsLoader(false)
  }
 
}
useEffect(() => {
    getProducts()
  }, [query, current])

  return (
      <>
       <Meta title="Страница поиска" />
        <div className='search-page ind-small'>
            <BtnScrollUp />
            <div className="container-custom">
                {query ? 
                      <div className='search-page__wrapper'>
                        {isLoader ? <div className="search-page__preloader"><Preloder size="70" color="#00348c" /></div> : <>
                            <div className="search-page__head mb-med">
                              <h1 className='search-page__title title'>Avtech</h1>
                              <div className="search-page__total">Найдено {getNoun(total)} по запросу: {query} </div>
                            </div>
                            <div className="search-page__wrapper">
                              <div className="search-page__items grid-three">
                                  {searchItems.map((item) => (
                                      <Card key={item.id} id={item.random_shop.item_shop_id} className={'card-product--style'} slug={item.slug} monthly_price={item.random_shop.monthly_price} price={item.random_shop.price} name={item.name} imageUrl={item.image} />
                                  ))}
                              </div>
                              <Pagination className='search-page__pagination pagination'
                                pageSize={perPage}
                                onChange={onChange}
                                current={current}
                                total={total}
                                jumpNextIcon={'...'}
                                prevIcon={'<'}
                                nextIcon={'>'}
                              />
                            </div> 
                        </> }
                      </div> 
                      :  
                      <SearchNotFound />
                }
            </div>
        </div>
      </>
  )
}

export default SearchPage